import '../../styles/auth.scss';
import React, {useState, useRef, useEffect} from 'react';
import {navigate} from 'gatsby';
import {useMutation} from '@apollo/react-hooks';
import {UserLoginState, UserLoginData} from '../../types';
import {Translate} from '../../components/translations';
import {
  setUserToken,
  setUserHash,
  setUserExists,
  getUserExists,
  setShowModal,
  getShowModal,
  removeUserToken,
  removeUserHash,
  getRawCookie,
  setDeviceKey,
  setRefreshToken,
} from '../../helpers';
import googleIcon from '../../../static/google-icon.svg';
import {USER_LOGIN, USER_LOGIN_TWO_FACTOR} from '../../graphql/mutations';
import Message from '../messages';
//import Checkbox from "../customCheckbox";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import VerifyCode from '../verify-code';
import {eyeIcon, lockClosedIcon, lockOpenIcon} from '../../images/svgIcons';
import Checkbox from '../customCheckbox';
import ChangeTwoFactor from '../changeTwoFactor';
import './styles.scss';

const Login = ({
  location,
  setShowLogin,
  setShowRegistration,
  setShowForgotPassword,
  setHandleCloseLoginModal,
}: any): JSX.Element => {
  const getPackageInfo = () => {
    return !!localStorage.getItem('checkout-data');
  };
  const inputRef = useRef(null);
  //--------------------State--------------------
  const [showConfirmationCode, setShowConfirmationCode] = useState(false);
  const [showTwoFactor, setShowTwoFactor] = useState(false);
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState('');
  //const [enableTwoFactor, setEnableTwoFactor] = useState(false);
  const [formData, setFormData] = useState<UserLoginState>({
    email: '',
    password: '',
    checked: false,
    error: false,
    errorMessage: '',
  });
  const [verificationType, setVerificationType] = useState('email');
  const [trusted, setTrusted] = useState(false);
  const [resendStatus, setResendStatus] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  //------------------Query--------------------
  const [loginMutation, {loading}] = useMutation<UserLoginData>(USER_LOGIN);
  const [verifyCode, {loading: verifyLoading}] = useMutation(USER_LOGIN_TWO_FACTOR);

  //----------useEffects------------------------
  useEffect(() => {
    resendStatus && setTimeout(() => setResendStatus(''), 4000);
  }, [resendStatus]);
  //----------------Functions-------------------
  const redirects = () => {
    setUserExists('true');
    if (localStorage.getItem('checkout-data')) {
      navigate('/pricing/checkout/');
    } else if (location?.state?.navigatePath) {
      navigate(location.state.navigatePath);
    } else {
      if (global && global.window && window.location.host.includes('localhost')) {
        window.location.href = 'http://localhost:3000';
      } else if (global && global.window && window.location.host.includes('37.186.119.181')) {
        window.location.href = 'http://37.186.119.181:3276/hexowatch';
      } else {
        window.location.href = 'https://dash.hexowatch.com/hexowatch';
      }
    }
  };

  const submitHandler = async () => {
    setFormData({...formData, error: false, errorMessage: ''});
    const {data} = await loginMutation({
      variables: {email: formData.email, password: formData.password, productType: 'hexowatch'},
    });
    if (!data) return;
    const {
      UserOps: {
        login: {error, message, token, hash, twofa_status, device_key, refresh_token},
      },
    } = data;
    if (error) {
      setFormData({
        ...formData,
        error: true,
        errorMessage:
          message === 'TOO_MANY_ATTEMPTS'
            ? 'This account has been locked. Please use the forgot password link to unlock it.'
            : message,
      });
    } else {
      device_key && setDeviceKey(device_key);
      if (!token.includes('twofa_verify')) {
        setUserToken(token);
        refresh_token && setRefreshToken(refresh_token);
        setUserHash(hash);
        setUserExists('true');
        if (!twofa_status && Math.round(Math.random()) && !getPackageInfo()) {
          setShowTwoFactor(true);
          setHandleCloseLoginModal && setHandleCloseLoginModal(true);
        } else redirects();
        return;
      }
      setShowConfirmationCode(true);
      removeUserToken();
      removeUserHash();
      setVerificationType(token.includes('app') ? 'Google Authentication app' : 'email');
      //For internal navigation to login page always provide {goBack: true} in state of Link or navigate
      // const goBack = location && location.state !== null && location.state.goBack;
      // if (goBack) {
      //   toggleLastURL();
      // } else {
      //     window.location.href='https://dash.hexowatch.com/hexowatch'
      // }
    }
  };

  const checkHandler = (): void =>
    setFormData({
      ...formData,
      checked: !formData.checked,
    });

  const fieldChangeHandler = (event: React.SyntheticEvent): void => {
    const target = event.target as HTMLInputElement;
    const {value, name} = target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Magnet Modal
  //const [show, setShow] = useState("false");
  global.document &&
    global.document.addEventListener('mousemove', e => {
      if (e.clientY < 20 && getShowModal() !== 'false' && getUserExists() !== 'true') {
        setShowModal('true');
        //setShow("true");
      }
    });

  const toRegistration = () => {
    // setShowLogin(false);
    // setShowRegistration(true);
    navigate('/registration');
  };

  const goToReset = (e: any) => {
    e.preventDefault();
    // setShowLogin(false);
    // setShowRegistration(false);
    setShowForgotPassword(true);
  };
  const handelKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') submitHandler();
  };
  const handelKeyDownEmail = (event: React.KeyboardEvent) => {
    //@ts-ignore
    if (event.key === 'Enter') inputRef.current.focus();
  };
  const handleVerifyCode = async () => {
    if (!code) {
      setCodeError('Invalid code');
      return;
    }
    const {data} = await verifyCode({
      variables: {
        code: code,
        email: formData.email,
        productType: 'hexowatch',
        rememberDevice: trusted,
        //base32: getRawCookie('base32'),
      },
    });
    if (data?.UserOps?.twofaVerify?.token) {
      setUserToken(data.UserOps.twofaVerify.token);
      setRefreshToken(data.UserOps.twofaVerify.refresh_token);
      setUserHash(data.UserOps.twofaVerify.hash);
      data.UserOps.twofaVerify.device_key && setDeviceKey(data.UserOps.twofaVerify.device_key);
      redirects();
    } else if (data.UserOps.twofaVerify.error) {
      setCodeError(
        data.UserOps.twofaVerify.message === 'INVALID_TWOFA_RESEND' ||
          data.UserOps.twofaVerify.message === 'CODE_SUCCESS_SENT'
          ? 'You have maxed out your attempts with your code. New generated code has been sent to your email.'
          : 'Invalid code',
      );
      setCode('');
    }
  };

  const resendCode = async () => {
    setCodeError('');
    const {data} = await verifyCode({
      variables: {
        code: 'send_to_email',
        email: formData.email,
        productType: 'hexowatch',
        base32: getRawCookie('base32'),
      },
    });
    if (data?.UserOps?.twofaVerify) {
      setResendStatus(data.UserOps.twofaVerify.message === 'CODE_SUCCESS_SENT' ? 'success' : 'error');
    }
  };
  const handleChangeTrusted = () => setTrusted(!trusted);

  useEffect(() => {
    if (showTwoFactor) {
      const el = document.getElementsByClassName('modal-body');
      el && el[0] && el[0].classList.add('modal-body-two-fa');
    } else {
      const el = document.getElementsByClassName('modal-body-two-fa');
      el && el[0] && el[0].classList.remove('modal-body-two-fa');
    }
  }, [showTwoFactor]);

  return (
    <div className="login-block">
      {showConfirmationCode ? (
        <>
          <div className="forms mt-2">
            <div className="form-item mb-1">
              <div className="d-flex justify-content-center mb-1">
                {!codeError && code.length === 6 ? lockOpenIcon : lockClosedIcon}
              </div>
              <h3 className="text-center">Enter verification code</h3>
              <VerifyCode
                length={6}
                setCode={setCode}
                //action={handleVerifyCode}
                setError={setCodeError}
                error={!!codeError}
              />
              <div className="text-center f_size_14">
                {verificationType === 'email'
                  ? 'A code has been sent to your email, please check and enter the six-digit verification code into the field to access your account.'
                  : 'Please check and enter the six-digit verification code from the Google Authenticator app into the field to access your account.'}
              </div>
            </div>

            <div className={`d-flex justify-content-center align-items-center`}>
              <Checkbox checked={trusted} click={handleChangeTrusted} />
              <span onClick={handleChangeTrusted} className="ml-2 f_w_400">
                Remember this device
              </span>
            </div>
            <div className={`text-center ${codeError ? 'visible' : 'invisible'}`}>
              <Message text={codeError} type={'error'} />
            </div>
          </div>
          <div className="submit-block my-0">
            <button className="submit-btn w-100" onClick={handleVerifyCode} style={{maxWidth: '245px'}}>
              {verifyLoading ? <FontAwesomeIcon icon={faSpinner} className="button-loading" /> : 'Verify'}
            </button>
          </div>
          <div className="clickable-text text-center pt-1 " id="resend-code" onClick={resendCode}>
            {verificationType === 'email' ? 'Resend code' : 'Send code to email'}
          </div>
          <div
            className={`clickable-text text-center pt-1 f_w_500 ${resendStatus ? 'visible' : 'invisible'} ${
              resendStatus === 'success' ? 'text-success' : 'text-danger'
            }`}
          >
            {resendStatus === 'success' ? 'Successfully sent' : 'Something went wrong'}
          </div>
        </>
      ) : showTwoFactor ? (
        <ChangeTwoFactor />
      ) : (
        <>
          <h4 className="title">Welcome back!</h4>
          <button
            className="gmail-btn"
            onClick={() => {
              setUserExists('true');
              window.location.href = `https://api.hexowatch.com/v2/app/social-callback/hexowatch/google?deviceKey=${getRawCookie(
                'device_key',
              ) || ''}${
                localStorage.getItem('checkout-data')
                  ? '&redirect=pricing/checkout'
                  : location?.state?.navigatePath
                  ? `&redirect=${location.state.navigatePath.slice(1)}`
                  : ''
              }`;
            }}
          >
            <img src={googleIcon} alt="Login with Google" width={30} /> <span>Sign in with Google</span>
          </button>
          <div className="or-div">
            <div>
              <Translate name="or" />
            </div>
            <hr />
          </div>
          <div className="forms">
            <div className="form-item">
              <label>Email</label>
              <input
                style={
                  formData.error
                    ? {
                        border: '1px',
                        borderColor: '#FB6F6F',
                        borderStyle: 'solid',
                      }
                    : {}
                }
                required
                placeholder={'Email'}
                type="email"
                name="email"
                onChange={fieldChangeHandler}
                onKeyDown={handelKeyDownEmail}
                value={formData.email}
              />
            </div>
            <div className="form-item position-relative password-form" style={{marginBottom: '20px'}}>
              <label>Password</label>
              <input
                style={
                  formData.error
                    ? {
                        border: '1px',
                        borderColor: '#FB6F6F',
                        borderStyle: 'solid',
                      }
                    : {}
                }
                required
                placeholder={'Password'}
                type={!showPassword ? 'password' : 'text'}
                name="password"
                onChange={fieldChangeHandler}
                onKeyDown={handelKeyDown}
                value={formData.password}
                ref={inputRef}
              />
              <span
                className={`eye-icon-pass ${showPassword ? ' active' : ''}`}
                onClick={() => setShowPassword(!showPassword)}
              >
                {eyeIcon}
              </span>
            </div>
            {formData.error && <Message type={'error'} text={<Translate name={formData.errorMessage} />} />}
          </div>
          <div className="keept-and-forgot-block">
            {/*<div className="toggle-checkbox">
          <Checkbox checked={formData.checked} click={checkHandler} />
          <span className="text">Keep me logged in?</span>
        </div>*/}
            <div className="forgotten-password">
              <a onClick={goToReset}>
                <Translate name="FORGOT_PASSWORD_QUESTION" />
              </a>
            </div>
          </div>
          <div className="submit-block">
            <button className="submit-btn" onClick={submitHandler}>
              {loading ? <FontAwesomeIcon icon={faSpinner} className="button-loading" /> : <Translate name="SIGN_IN" />}
            </button>
          </div>
          <div className="forgot">
            <span>Not a member?&nbsp;</span>
            <span className="create-accout-text" onClick={toRegistration}>
              <Translate name="CREATE_ACCOUNT" />
            </span>
          </div>
        </>
      )}
    </div>

    // <div className={`modal show login-modal`} role="dialog">
    //     <div className="modal-content">
    //       <div className="row">
    //         <div className="col-md-7 col-xs-12 left-body">
    //           <div className="modal-header">
    //             <div>
    //               <img
    //                 src={logo}
    //                 alt="Hexowatch.com"
    //                 width={210}
    //                 height={50}
    //               />
    //             </div>
    //           </div>
    //           <div className="modal-container">
    //           <div className="modal-body">
    //             <div className="body-container">
    //               <div className="login-title">
    //                 <Translate name="LOGIN_TO_YOUR_ACCOUNT"/>
    //               </div>
    //               <div className="form-content">
    //                 <div className='text-center mt-4'>
    //                   <Button light transparent onClick={() => {setUserExists('true'); window.location.href = 'https://api.hexowatch.com/v2/app/social-callback/hexowatch/google'}}>
    //                     <img src={googleIcon} alt='Login with Google' width={30} /> Sign in with Google
    //                   </Button>
    //                 </div>
    //                 <div className="or-div">
    //                   <div>
    //                     <Translate name="OR"/>
    //                   </div>
    //                   <hr/>
    //                 </div>
    //                 <Form
    //                   className="mt_40 login-form"
    //                   onSubmit={submitHandler}
    //                 >
    //                   <FormItem>
    //                     <Translate name="EMAIL">
    //                       {text => (
    //                         <Input
    //                           style={formData.error ? {border: '1px', borderColor: 'red', borderStyle: 'solid'} : {}}
    //                           required
    //                           placeholder={text}
    //                           type="email"
    //                           name="email"
    //                           onChange={fieldChangeHandler}
    //                           value={formData.email}
    //                         />
    //                       )}
    //                     </Translate>
    //                   </FormItem>
    //                   <FormItem>
    //                     <Translate name="PASSWORD">
    //                       {text => (
    //                         <Input
    //                           style={formData.error ? {border: '1px', borderColor: 'red', borderStyle: 'solid'} : {}}
    //                           required
    //                           placeholder={text}
    //                           type="password"
    //                           name="password"
    //                           onChange={fieldChangeHandler}
    //                           value={formData.password}
    //                         />
    //                       )}
    //                     </Translate>
    //                   </FormItem>
    //                   {formData.error && (
    //                     <div className="error-message">
    //                       <Translate name={formData.errorMessage} />
    //                     </div>
    //                   )}
    //                   <div className="extra">
    //                     <div className="checkbox remember">
    //                       <label>
    //                         <input
    //                           type="checkbox"
    //                           checked={formData.checked}
    //                           onChange={checkHandler}
    //                         />
    //                         <Translate name="KEEP_ME_LOGGED_IN"/>
    //                       </label>
    //                     </div>
    //                     <div className="forgotten-password">
    //                       <a onClick={() => gotToWithReplace('/forgot-password')}>
    //                         <Translate name="FORGOT_PASSWORD_QUESTION"/>
    //                       </a>
    //                     </div>
    //                   </div>
    //                   <Button primary className="btn_three" loading={loading}>
    //                     <Translate name="SIGN_IN"/>
    //                   </Button>
    //                 </Form>
    //               </div>
    //             </div>
    //           </div>
    //           </div>
    //         </div>
    //         <div className="col-md-5 col-xs-12 auth-right-content">
    //           <div className="bg-image">
    //             <button
    //               type="button"
    //               onClick={toggleCloseLoginModalURL}
    //               className="close"
    //               data-dismiss="modal"
    //               aria-label="Close"
    //             >
    //               <span aria-hidden="true">&times;</span>
    //             </button>
    //             <div className="auth-text-block">
    //               <img src={LoginImage} alt ="login"/>
    //               <div className="auth-texts">
    //                 <div className="new-here">
    //                   <Translate name="NEW_HERE"/>
    //                 </div>
    //                 <div className="create-account">
    //                   <Translate name="CREATE_YOUR_ACCOUNT_NOW"/>
    //                 </div>
    //                 <Button light onClick={() => gotToWithReplace('/registration')}>
    //                   <Translate name="CREATE_ACCOUNT"/>
    //                 </Button>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   <SubscriptionModal isModalOpen={show === 'false' ? false : true} />
    // </div>
  );
};

export default Login;
