import React, {useState} from 'react';
import {navigate} from 'gatsby';
import {useMutation} from '@apollo/react-hooks';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {USER_FORGOT_PASSWORD} from '../../graphql/mutations';
import {Translate} from '../../components/translations';
import {UserForgotState, ForgotPasswordMutationData} from '../../types';
import Message from '../messages';
import {forgetPasswordIcon} from '../../images/svgIcons';
import './styles.scss';

const Forgot = ({setShowForgotPassword}: any): JSX.Element => {
  const [forgotMutation, {loading}] = useMutation<ForgotPasswordMutationData>(USER_FORGOT_PASSWORD);

  const [formData, setFormData] = useState<UserForgotState>({
    email: '',
    error: false,
    errorMessage: '',
    isModalOpen: false,
  });

  const fieldChangeHandler = (event: React.SyntheticEvent): void => {
    const target = event.target as HTMLInputElement;
    const {value, name} = target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const submitHandler = async () => {
    setFormData({
      ...formData,
      error: false,
      errorMessage: '',
    });

    const {data} = await forgotMutation({
      variables: {email: formData.email, type: 'hexowatch'},
    });
    if (data) {
      const {
        UserOps: {
          forgotPassword: {error, message},
        },
      } = data;
      if (error) {
        setFormData({
          ...formData,
          error: true,
          errorMessage: message,
        });
      } else {
        setFormData({
          ...formData,
          isModalOpen: true,
        });
      }
    }
  };

  const closeModal = () => {
    setShowForgotPassword(false);
    navigate('/');
  };

  return (
    <div className="forgot-password">
      {!formData.isModalOpen ? (
        <>
          <div className="text-center forgot-icon">{forgetPasswordIcon}</div>
          <h4 className="title">Forgot your password?</h4>
          <div className="forms">
            <div className="form-item">
              <input
                required
                placeholder={'Email'}
                type="email"
                name="email"
                onChange={fieldChangeHandler}
                value={formData.email}
              />
            </div>
            {formData.error && <Message type={'error'} text={<Translate name={formData.errorMessage} />} />}
            <div className="submit-block">
              <button className="submit-btn" onClick={submitHandler}>
                {loading ? (
                  <FontAwesomeIcon icon={faSpinner} className="button-loading" />
                ) : (
                  <Translate name="GET_RESET_LINK" />
                )}
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <h4 className="title">
            <Translate name="FORGOT_SUCCESS_MESSAGE" />
          </h4>
          <div className="submit-block">
            <button className="submit-btn" onClick={closeModal}>
              OK
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Forgot;
